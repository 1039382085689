<template>
  <div class="page page__content-wrapper" :ref="'top'">

    <button class="btn btn__circle btn__nav-slider btn__top" :class="{visible: activeBtnTop}" @click="scrollTo('top', $event)">
      <img src="~assets/img/icons/icon-row.svg" alt="next">
    </button>

    <div class="page__min-container">
      <ul class="nav">
        <li class="nav__item">
          <a href="#" @click.prevent="scrollTo( 'photo', $event)" class="nav__link">Фотофон</a>
        </li>
        <li class="nav__item">
          <a href="#" @click.prevent="scrollTo('hud', $event)" class="nav__link">HUD-элементы</a>
        </li>
        <li class="nav__item">
          <a href="#" @click.prevent="scrollTo('typo', $event)" class="nav__link">Типографика</a>
        </li>
      </ul>
    </div>


    <div class="page__full-width">
      <page-item v-for="(item, idx) in images" :content="item" :key="idx" :ref="item.id"></page-item>

      <div class="page__min-container">
        <ButtonDownload
          text-button="Скачать все фоны"
          url="03-Identity/Steor-Backgrounds.zip"
          class="page__download-block"
          :icons="[{url: '', icon: 'png.svg'}]"
        />
      </div>

      <page-item v-for="(item, idx) in imagesSecond" :content="item" :key="(idx + 1) * 100" :ref="item.id"></page-item>

      <typography-item :item="typoRoot" class="typo-item_root"></typography-item>

      <div class="page__min-container">
        <div class="text__normal">
          Для разметки технической информации HUD-элементов используется шрифт PT&nbsp;Root&nbsp;IU&nbsp;Medium.
        </div>
      </div>

      <div class="page__min-container">
        <ButtonDownload
          text-button="Скачать все материалы"
          url="03-Identity/Steor-HUDs.zip"
          class="page__download-block big"
          :icons="[{url: '', icon: 'ai.svg'}, {url: '', icon: 'figma.svg'}, {url: '', icon: 'png.svg'}]"
        />
      </div>

      <page-item
        v-for="(item, idx) in imagesTree"
        :content="item"
        :key="(idx + 1) * 200"
        :ref="item.id"
        class="page-item_three"
      ></page-item>

      <typography-item :item="typoStapel" class="typo-item_stapel"></typography-item>

      <page-item v-for="(item, idx) in imagesFour" :content="item" :key="(idx + 1) * 300"
                 class="page-item_four"></page-item>

      <div class="page__min-container">
        <div class="text__normal">
          Для создания узнаваемых фирменных макетов рекомендуется использовать прием — поворот одного из текстовых
          блоков на 90 градусов.
        </div>

        <div class="colors">
          <div class="colors__wrapper">
            <div class="colors__item">
              <span class="colors__type">Hex:</span>
              <span class="colors__text">A97436</span>
            </div>
            <div class="colors__item">
              <span class="colors__type">Hex:</span>
              <span class="colors__text">C98A40</span>
            </div>
            <div class="colors__item">
              <span class="colors__type">Hex:</span>
              <span class="colors__text">E7A659</span>
            </div>
          </div>
        </div>

        <div class="text__normal">
          Для написания адреса сайта рекомендуется использовать дополнительные цвета оттенков Sand. На темном фоне —
          более темный оттенок, на белом — самый светлый. Подробнее в разделе
          <router-link to="/identity/color" class="last-word">Цвета</router-link>.
        </div>
      </div>

    </div>


    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import ButtonDownload from "@/components/Elements/ButtonDownload/ButtonDownload"
import PageItem from "@/components/Elements/PageItem/PageItem"
import TypographyItem from "../../../components/Elements/TypographyItem/TypographyItem"

export default {
  components: {
    TypographyItem,
    PageItem,
    ButtonDownload,
    BottomNavBlock
  },

  data() {
    return {
      bottomNav: {
        pre: {
          url: "/identity/typography",
          text: "Типографика"
        },
        next: {
          url: "/identity/picto",
          text: "Пиктограммы"
        }
      },
      images: [
        {
          type: "image",
          text: [
            "В качестве визуалов Стеор использует реалистичные (в противовес «карамельным») фотографии и видео коммерческих судов, портов, морских пространств, а также береговой инфраструктуры. Предпочтение всегда отдается «промышленным» ракурсам — с дронов, со спутников или просто с дальних точек.",
            "Визуалы могут использоваться как сами по себе, так и в связке с декоративными HUD‑элементами, располагающимися поверх фотографий и видео.",
            "HUD-элементы не обязательно должны являться настоящими элементами навигационных интерфейсов. Они выполняют стилеобразующую роль и подчеркивают имидж Стеора как современной цифровой компании, определяющей будущее морской навигации."
          ],
          images: [
            {
              img: "style-page/style-01.png"
            }
          ]
        },
        {
          id: "photo",
          type: "image",
          title: "Фотофон",
          text: [
            "Может использоваться для оформления презентаций, сайтов и любых других материалов. Правила подбора визуалов описаны в разделе <a href='#/identity/photo-style' class='last-word'>Фото и видео</a>."
          ],
          images: [
            {
              img: "style-page/photo-01.png",
              icons: [
                {
                  url: "../download-file/03-Identity/Steor-Background-01.png",
                  icon: "icon-png.svg"
                }
              ]
            },
            {
              img: "style-page/photo-02.png",
              icons: [
                {
                  url: "../download-file/03-Identity/Steor-Background-02.png",
                  icon: "icon-png.svg"
                }
              ]
            },
            {
              img: "style-page/photo-03.png",
              icons: [
                {
                  url: "../download-file/03-Identity/Steor-Background-03.png",
                  icon: "icon-png.svg"
                }
              ]
            },
            {
              img: "style-page/photo-04.png",
              icons: [
                {
                  url: "../download-file/03-Identity/Steor-Background-04.png",
                  icon: "icon-png.svg"
                }
              ]
            }
          ]
        }
      ],
      imagesSecond: [
        {
          id: "hud",
          type: "image",
          title: "HUD-элементы",
          text: [
            "HUD-элементы (от английского Heads Up Display) являются стилеобразующими для айдентики Стеора.",
            "Они могут использоваться как поверх фотографий и видео, так и поверх сплошных заливок.",
            "Фирменный стиль включает в себя ряд заготовок, которые можно использовать как есть, или же модифицировать и комбинировать на свое усмотрение. Это позволяет быстро получать различные дизайнерские решения, которые будут выглядеть комплексно и при этом не выходить за рамки айдентики."
          ],
          images: [
            {
              img: "style-page/hud-01.png",
              icons: [
                {
                  url: "../download-file/03-Identity/Steor-HUDs-01.ai",
                  icon: "icon-ai.svg"
                }
              ]
            },
            {
              img: "style-page/hud-02.png",
              icons: [
                {
                  url: "../download-file/03-Identity/Steor-HUDs-02.ai",
                  icon: "icon-ai.svg"
                }
              ]
            },
            {
              img: "style-page/hud-03.png",
              icons: [
                {
                  url: "../download-file/03-Identity/Steor-HUDs-03.ai",
                  icon: "icon-ai.svg"
                }
              ]
            },
            {
              img: "style-page/hud-04.png",
              icons: [
                {
                  url: "../download-file/03-Identity/Steor-HUDs-04.ai",
                  icon: "icon-ai.svg"
                }
              ]
            },
            {
              img: "style-page/hud-05.png",
              icons: [
                {
                  url: "../download-file/03-Identity/Steor-HUDs-05.ai",
                  icon: "icon-ai.svg"
                }
              ]
            },
            {
              img: "style-page/hud-06.png",
              icons: [
                {
                  url: "../download-file/03-Identity/Steor-HUDs-06.ai",
                  icon: "icon-ai.svg"
                }
              ]
            }
          ]
        }
      ],
      imagesTree: [
        {
          id: "typo",
          type: "image",
          title: "Типографика",
          images: [
            {
              img: "style-page/typo-01.png"
            }
          ]
        }
      ],
      imagesFour: [
        {
          type: "image",
          text: "Менять в заголовках фирменный шрифт на дополнительный допускается только в презентациях PowerPoint — в тех случаях, когда установка шрифта Stapel на используемый для создания макета компьютер невозможна или нецелесообразна. Подробнее в разделе <a href='#/identity/typography' class='last-word'>Типографика</a>.",
          images: [
            {
              img: "style-page/typo-02.png"
            }
          ]
        }
      ],
      typoRoot: {
        fonts: [
          {
            name: "PT Root UI Medium",
            text: "typography-page/pt-root/pt-root-03.svg"
          }
        ]
      },
      typoStapel: {
        description: [
          "Шрифт Stapel Semi Expanded Medium является обязательным элементом фирменного стиля. Для набора имиджевых заголовков и адреса сайта рекомендуется использовать прописные буквы с заметной разрядкой 5–15%. Данные указания не относятся к набору больших объемов текста, а также тексту в малых и средних кеглях.",
          "В случае нехватки места на макете, допускается заменить Stapel Semi Expanded Medium на Stapel Medium."
        ],
        fonts: [
          {
            name: "Stapel Semi Expanded Medium",
            text: "typography-page/stapel/stapel-01.svg",
            icons: [
              {
                url: "../download-file/03-Identity/Stapel_Semi-Expanded-Medium.otf",
                icon: "icon-otf.svg"
              },
              {
                url: "../download-file/03-Identity/Stapel_Semi-Expanded-Medium.woff",
                icon: "icon-woff.svg"
              }
            ]
          },
          {
            name: "Stapel Medium",
            text: "typography-page/stapel/stapel-02.svg",
            icons: [
              {
                url: "../download-file/03-Identity/Stapel_Medium.otf",
                icon: "icon-otf.svg"
              },
              {
                url: "../download-file/03-Identity/Stapel_Medium.woff",
                icon: "icon-woff.svg"
              }
            ]
          }
        ]
      },
      windowTop: window.top.scrollY,
      activeBtnTop: false
    }
  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY
    },
    scrollTo(id, event) {
      if (id === "top") {
        window.scrollBy({
          top: -99999999999,
          behavior: "smooth"
        })
      } else {
        let ref = null
        if (Array.isArray(this.$refs[id])) {
          ref = this.$refs[id][0].$el
        } else {
          ref = this.$refs[id]
        }

        const topOffset = event.target.offsetHeight + 100
        const elementPosition = ref.getBoundingClientRect().top
        const offsetPosition = elementPosition - topOffset

        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth"
        })
      }
    }
  },
  watch: {
    windowTop(value) {
      this.activeBtnTop = value > 400
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/var-style";
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";
@import "../../../assets/style/buttons";

.nav {
  margin-bottom: 96px;

  &__item {
    list-style: none;
    margin-bottom: 12px;
  }

  &__link {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #505B66;
  }
}

.colors {
  margin-top: 48px;
  margin-bottom: 32px;

  &__wrapper {
    display: flex;
  }

  &__item {
    min-width: 120px;
    max-width: 120px;
    font-size: 13px;
    line-height: 18px;
    color: #fff;
    border-radius: 24px;
    padding: 7px 16px;
    margin-right: 24px;

    &:first-child {
      background-color: #A97436;
    }

    &:nth-child(2) {
      background-color: #C98A40;
    }

    &:last-child {
      background-color: #E7A659;
      margin-right: 0;
    }
  }

  &__type {
    opacity: 0.6;
    margin-right: 4px;
  }
}

/deep/ .last-word,
.last-word {
  color: inherit;
  text-decoration: underline;
}

.btn {
  display: none;

  &.visible {
    display: block;
  }

  &__top {
    position: fixed;
    bottom: 100px;
    right: 56px;

    img {
      transform: rotate(90deg);
    }
  }
}

/deep/ .page-item {
  &__title {
    letter-spacing: 0;
  }

  &__text-item {
    letter-spacing: 0;
  }

  &_three {
    .page-item__title {
      margin-bottom: 48px;
    }
  }

  &_four {
    margin-top: 88px;
    margin-bottom: 48px;

    .page-item__text {
      letter-spacing: 0;
    }
  }
}

/deep/ .typo-item {
  margin-top: -48px;

  &__text {
    margin-bottom: 96px;
  }

  &_stapel {
    .typo-item__text {
      margin-bottom: 96px;
      max-width: 682px;
    }

    .font {

    }
  }

  &_root {
    margin-top: -48px;
  }
}

/deep/ .font {
  margin-bottom: 40px;

  &__wrapper {
    //margin-bottom: 32px;
  }
}

///deep/ .download-block {
//  margin-left: 24px;
//}

.text {
  &__normal {
    max-width: 688px;
  }
}

/deep/ .page__download-block.big {
  .main-btn_download {
    padding-right: 26px;
  }
}

.page {
  margin-top: 104px;
  margin-bottom: 192px;

  &__min-container {
    margin-bottom: 96px;
  }
}

///deep/.main-btn_download {
//  padding-right: 25px;
//}

@media screen and (max-width: 1540px) {
  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 263px;
  }
}


@media screen and (max-width: 1320px) {
  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1216px) {
  .page__link-block.download-block {
    display: none;
  }
}

@media screen and (max-width: 1090px) {
  .btn.visible {
    display: none;
  }

  .page {
    margin-top: 0;
    padding-top: 18px;
  }
}

@media screen and (max-width: 896px) {
  .nav {
    margin-bottom: 80px;
  }

  /deep/ .page-item {
    &__wrapper {
      margin-bottom: 32px;
    }

    &__title {
      margin-bottom: 25px;
    }

    &__text {
      margin-bottom: 32px;
    }

    &__image {
      min-height: 172px;
      border-radius: 4px;
    }

    &_three {
      .page-item__title {
        margin-bottom: 32px;
      }
    }

    &_four {
      margin-top: 56px;
      margin-bottom: 32px;
    }
  }

  /deep/ .typo-item {
    &_stapel {
      margin-bottom: 32px;

      .font {
        margin-bottom: 24px;
      }

      .typo-item__text {
        margin-bottom: 65px;
      }
    }

    &_root {
      margin-top: -64px;
    }
  }

  .colors {
    margin-top: 32px;
    margin-bottom: 17px;

    &__wrapper {
      flex-direction: column;
    }

    &__item {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    padding-top: 48px;
    margin-bottom: 191px;

    &__min-container {
      margin-bottom: 80px;
    }

    &__full-width {
      width: 100%;
    }

    &__download-block {
      display: none;
    }
  }
}

</style>
