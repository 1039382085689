<template>
  <div class="typo-item">
    <div class="page__min-container">
      <div class="typo-item__title text__head">
        {{ item.title }}
      </div>
      <div class="typo-item__text text__normal">
        <div
          class="typo-item__text-item"
          v-for="text in item.description"
          :key="text"
          v-html="text"
        ></div>
      </div>
    </div>

    <div class="font" v-for="font in item.fonts" :key="font.type">
      <div class="font__wrapper">
        <div class="page__min-container">
          <div class="font__name">
            {{ font.name }}
          </div>
          <div
            class="font__text"

          >
            <img
              class="type-item__image"
              :src="require(`@/assets/img/${font.text}`)"
              alt=""
            />
          </div>
        </div>

        <div class="font__download">
          <icon-download
            v-for="icon in font.icons"
            :key="icon.icon"
            :url="icon.url"
            :icon="icon.icon"
          ></icon-download>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconDownload from "../IconDownload/IconDownload"

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {IconDownload}
}
</script>

<style scoped lang="scss">
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";


.typo-item {
  &__title {
    font-size: 26px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 32px;
  }

  &__text {
    max-width: 676px;
    margin-bottom: 48px;

    &-item {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    & img {
      width: 100%;
    }
  }
}

.font {
  &__name {
    display: inline-block;
    background-color: #fff;
    color: #697480;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.3px;
    border-radius: 50px;
    padding: 7px 15px;
    margin-bottom: 24px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
  }

  &__text {
    font-size: 32px;
    line-height: 125%;
  }

  &__download {
    display: flex;
    margin-bottom: 14px;
  }
}

.page {
  &__min-container {
    margin-right: 32px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1370px) {
  .font {
    &__download {
      display: none;
    }
  }

  .page {
    &__min-container {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 896px) {
  .typo-item {
    &__title {
      margin-bottom: 23px;
    }
  }

  .font {
    &__name {
      margin-bottom: 16px;
    }

    &__text {
      width: 100%;

      & img {
        width: 100%;
      }
    }
  }
}
</style>